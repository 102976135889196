<template>
  <v-app>
    <Login v-if="!appAccess()" />
    <TopNavigation v-if="appAccess()" />
    <v-main v-if="appAccess()" class="mt-4 mb-3">
      <router-view class="mt-16 ml-4 mr-4 mb-16"></router-view>
    </v-main>
    <BottomNavigation v-if="appAccess()" />
  </v-app>
</template>


<script>
import Login from './components/auth/Login.vue'
import TopNavigation from './components/fixedComponents/TopNavigation.vue'
import BottomNavigation from './components/fixedComponents/BottomNavigation.vue'
export default {
  name: 'App',
  components: { 
    TopNavigation, 
    BottomNavigation,
    Login
  },
  data () {
    return {
      isPartnerLoggedIn: false
    }
  },
  methods: {
    appAccess () {
      if(this.$store.state.isPartnerLoggedIn) {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {

  }
}
</script>
<style scoped>
</style>
