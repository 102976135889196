<template>
  <svg width="511" height="511" viewBox="0 0 511 511" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M319.122 154.378C342.627 131.949 354.724 102.452 355.044 73.7426C355.091 69.497 351.804 66.0506 347.563 65.9024C318.869 64.8732 288.838 75.5749 265.329 98.0076C239.816 122.351 227.746 155.013 229.582 185.968C260.417 189.255 293.609 178.725 319.122 154.378Z" fill="#B3E59F"/>
<path d="M260.732 168.268C258.763 168.268 256.79 167.559 255.219 166.124C251.901 163.083 251.675 157.925 254.716 154.604L291.539 114.413C294.587 111.103 299.741 110.877 303.063 113.91C306.381 116.955 306.607 122.113 303.562 125.434L266.743 165.625C265.133 167.376 262.938 168.268 260.732 168.268Z" fill="#95D6A4"/>
<path d="M256.725 139.984C252.576 139.984 249.025 136.83 248.619 132.619C241.477 58.9121 224.436 11.4112 224.268 10.9434C222.732 6.71338 224.912 2.03113 229.142 0.495074C233.356 -1.04878 238.054 1.13835 239.59 5.36056C240.315 7.36055 257.489 55.107 264.849 131.044C265.286 135.528 262.007 139.516 257.524 139.945C257.251 139.968 256.99 139.984 256.725 139.984Z" fill="#78C2A4"/>
<path d="M224.701 89.2084C194.892 70.6743 160.768 65.9843 130.651 73.4033C126.195 74.4989 123.481 78.8653 124.468 83.3448C131.142 113.641 150.444 142.163 180.257 160.697C212.608 180.814 250.039 184.623 281.921 174.343C277.033 141.204 257.056 109.321 224.701 89.2084Z" fill="#95D6A4"/>
<path d="M260.136 173.723C258.549 173.723 256.95 173.259 255.539 172.296L185.902 124.662C182.183 122.121 181.235 117.048 183.777 113.329C186.307 109.61 191.387 108.662 195.107 111.204L264.744 158.838C268.463 161.38 269.41 166.452 266.872 170.171C265.293 172.479 262.736 173.723 260.136 173.723Z" fill="#78C2A4"/>
<path d="M354.24 290.837C354.24 344.876 310.431 388.685 256.393 388.685C202.35 388.685 158.541 344.876 158.541 290.837C158.541 236.795 202.35 192.986 256.393 192.986C310.431 192.986 354.24 236.795 354.24 290.837Z" fill="#DB4655"/>
<path d="M362.396 201.138C362.396 223.656 344.143 241.91 321.624 241.91C299.106 241.91 280.853 223.656 280.853 201.138C280.853 178.623 299.106 160.366 321.624 160.366C344.143 160.366 362.396 178.623 362.396 201.138Z" fill="#E5646E"/>
<path d="M337.932 225.602C315.418 225.602 297.16 207.348 297.16 184.83C297.16 177.781 298.969 171.111 302.12 165.325C289.426 172.233 280.853 185.672 280.853 201.138C280.853 223.656 299.11 241.91 321.624 241.91C337.094 241.91 350.529 233.336 357.437 220.643C351.652 223.793 344.981 225.602 337.932 225.602Z" fill="#DB4655"/>
<path d="M231.929 201.138C231.929 223.656 213.676 241.91 191.158 241.91C168.639 241.91 150.386 223.656 150.386 201.138C150.386 178.623 168.639 160.366 191.158 160.366C213.676 160.366 231.929 178.623 231.929 201.138Z" fill="#E5646E"/>
<path d="M207.465 225.602C184.951 225.602 166.694 207.348 166.694 184.83C166.694 177.781 168.507 171.111 171.653 165.329C158.963 172.237 150.386 185.672 150.386 201.138C150.386 223.656 168.639 241.91 191.158 241.91C206.627 241.91 220.058 233.333 226.966 220.639C221.181 223.789 214.514 225.602 207.465 225.602Z" fill="#DB4655"/>
<path d="M297.161 192.986C297.161 215.504 278.907 233.757 256.393 233.757C233.874 233.757 215.621 215.504 215.621 192.986C215.621 170.467 233.874 152.214 256.393 152.214C278.907 152.214 297.161 170.467 297.161 192.986Z" fill="#E5646E"/>
<path d="M272.701 217.446C250.182 217.446 231.929 199.192 231.929 176.678C231.929 169.625 233.734 162.951 236.884 157.165C224.19 164.078 215.621 177.516 215.621 192.986C215.621 215.5 233.874 233.754 256.393 233.754C271.859 233.754 285.293 225.181 292.206 212.491C286.42 215.637 279.75 217.446 272.701 217.446Z" fill="#DB4655"/>
<path d="M297.161 470.229C297.161 492.747 278.907 511 256.393 511C233.874 511 215.621 492.747 215.621 470.229C215.621 447.71 233.874 429.457 256.393 429.457C278.907 429.457 297.161 447.71 297.161 470.229Z" fill="#E5646E"/>
<path d="M272.701 494.692C250.182 494.692 231.929 476.439 231.929 453.921C231.929 446.872 233.75 440.213 236.9 434.431C224.206 441.34 215.617 454.763 215.617 470.229C215.621 492.747 233.874 511 256.393 511C271.859 511 285.293 502.423 292.202 489.733C286.42 492.88 279.75 494.692 272.701 494.692V494.692Z" fill="#DB4655"/>
<path d="M264.545 404.997C264.545 427.511 246.291 445.765 223.773 445.765C201.258 445.765 183.001 427.511 183.001 404.997C183.001 382.478 201.258 364.225 223.773 364.225C246.291 364.225 264.545 382.478 264.545 404.997V404.997Z" fill="#E5646E"/>
<path d="M240.085 429.457C217.566 429.457 199.313 411.203 199.313 388.685C199.313 381.636 201.063 375.106 204.213 369.324C191.519 376.233 183.005 389.527 183.005 404.993C183.005 427.511 201.258 445.765 223.773 445.765C239.243 445.765 252.662 437.176 259.57 424.482C253.784 427.632 247.129 429.457 240.085 429.457Z" fill="#DB4655"/>
<path d="M329.781 404.997C329.781 427.511 311.528 445.765 289.009 445.765C266.491 445.765 248.237 427.511 248.237 404.997C248.237 382.478 266.491 364.225 289.009 364.225C311.528 364.225 329.781 382.478 329.781 404.997V404.997Z" fill="#E5646E"/>
<path d="M305.317 429.457C282.799 429.457 264.545 411.203 264.545 388.685C264.545 381.636 266.335 374.946 269.485 369.161C256.791 376.069 248.237 389.527 248.237 404.993C248.237 427.511 266.491 445.765 289.009 445.765C304.479 445.765 317.914 437.192 324.822 424.498C319.036 427.648 312.366 429.457 305.317 429.457Z" fill="#DB4655"/>
<path d="M362.396 339.761C362.396 362.28 344.143 380.533 321.624 380.533C299.106 380.533 280.853 362.28 280.853 339.761C280.853 317.243 299.106 298.989 321.624 298.989C344.143 298.989 362.396 317.243 362.396 339.761Z" fill="#E5646E"/>
<path d="M337.932 364.225C315.418 364.225 297.16 345.972 297.16 323.453C297.16 316.405 298.938 309.699 302.084 303.913C289.391 310.822 280.853 324.291 280.853 339.761C280.853 362.28 299.11 380.533 321.624 380.533C337.094 380.533 350.529 371.96 357.437 359.266C351.652 362.412 344.981 364.225 337.932 364.225Z" fill="#DB4655"/>
<path d="M231.929 339.761C231.929 362.28 213.676 380.533 191.158 380.533C168.639 380.533 150.386 362.28 150.386 339.761C150.386 317.243 168.639 298.989 191.158 298.989C213.676 298.989 231.929 317.243 231.929 339.761Z" fill="#E5646E"/>
<path d="M207.465 364.225C184.951 364.225 166.694 345.972 166.694 323.453C166.694 316.405 168.514 309.742 171.661 303.956C158.971 310.865 150.386 324.292 150.386 339.761C150.386 362.28 168.643 380.533 191.158 380.533C206.627 380.533 220.089 371.983 226.998 359.289C221.212 362.44 214.514 364.225 207.465 364.225V364.225Z" fill="#DB4655"/>
<path d="M297.161 331.605C297.161 354.124 278.907 372.377 256.393 372.377C233.874 372.377 215.621 354.124 215.621 331.605C215.621 309.091 233.874 290.837 256.393 290.837C278.907 290.837 297.161 309.091 297.161 331.605V331.605Z" fill="#E5646E"/>
<path d="M272.701 356.069C250.182 356.069 231.929 337.816 231.929 315.297C231.929 308.249 233.543 301.383 236.689 295.598C223.999 302.506 215.621 316.14 215.621 331.605C215.621 354.124 233.874 372.377 256.393 372.377C271.859 372.377 285.328 363.839 292.237 351.145C286.455 354.295 279.75 356.069 272.701 356.069V356.069Z" fill="#DB4655"/>
<path d="M199.313 274.529C199.313 297.044 181.06 315.297 158.541 315.297C136.023 315.297 117.77 297.044 117.77 274.529C117.77 252.011 136.023 233.758 158.541 233.758C181.06 233.758 199.313 252.011 199.313 274.529V274.529Z" fill="#E5646E"/>
<path d="M174.849 298.989C152.331 298.989 134.077 280.736 134.077 258.217C134.077 251.169 135.89 244.498 139.037 238.717C126.343 245.625 117.77 259.06 117.77 274.525C117.77 297.044 136.023 315.297 158.541 315.297C174.011 315.297 187.454 306.736 194.362 294.042C188.58 297.192 181.898 298.989 174.849 298.989V298.989Z" fill="#DB4655"/>
<path d="M395.012 274.529C395.012 297.044 376.759 315.297 354.241 315.297C331.726 315.297 313.469 297.044 313.469 274.529C313.469 252.011 331.726 233.758 354.241 233.758C376.759 233.758 395.012 252.011 395.012 274.529V274.529Z" fill="#E5646E"/>
<path d="M370.552 298.989C348.034 298.989 329.781 280.736 329.781 258.218C329.781 251.169 331.578 244.487 334.724 238.701C322.03 245.61 313.469 259.06 313.469 274.526C313.469 297.044 331.726 315.297 354.241 315.297C369.71 315.297 383.141 306.72 390.049 294.027C384.268 297.177 377.597 298.989 370.552 298.989Z" fill="#DB4655"/>
<path d="M329.781 266.373C329.781 288.888 311.528 307.145 289.009 307.145C266.491 307.145 248.237 288.888 248.237 266.373C248.237 243.855 266.491 225.602 289.009 225.602C311.528 225.602 329.781 243.855 329.781 266.373Z" fill="#E5646E"/>
<path d="M305.317 290.837C282.799 290.837 264.545 272.58 264.545 250.066C264.545 243.017 266.335 236.323 269.481 230.537C256.787 237.446 248.237 250.904 248.237 266.374C248.237 288.892 266.491 307.145 289.009 307.145C304.475 307.145 317.917 298.576 324.826 285.882C319.044 289.032 312.366 290.837 305.317 290.837V290.837Z" fill="#DB4655"/>
<path d="M264.545 266.373C264.545 288.888 246.291 307.145 223.773 307.145C201.258 307.145 183.001 288.888 183.001 266.373C183.001 243.855 201.258 225.602 223.773 225.602C246.291 225.602 264.545 243.855 264.545 266.373Z" fill="#E5646E"/>
<path d="M240.085 290.837C217.566 290.837 199.313 272.58 199.313 250.066C199.313 243.017 201.11 236.335 204.26 230.549C191.566 237.461 183.001 250.904 183.001 266.374C183.001 288.892 201.258 307.145 223.773 307.145C239.243 307.145 252.673 298.568 259.586 285.878C253.8 289.025 247.129 290.837 240.085 290.837Z" fill="#DB4655"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="511" height="511" fill="white"/>
</clipPath>
</defs>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>