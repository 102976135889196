<template>

  <v-bottom-navigation elevation="5" dark grow color="#4B9C6D" background-color="#434242" fixed>

    <v-btn @click="toHome()">
      <span class="font-weight-bold mt-1">Home</span>
      <v-icon>{{this.homeIcon}}</v-icon>
    </v-btn>

    <v-btn @click="toCustomers()">
      <span class="font-weight-bold mt-1">Customers</span>
      <v-icon>{{this.customersIcon}}</v-icon>
    </v-btn>

    <v-btn @click="toPayments()">
      <span class="font-weight-bold mt-1">Payments</span>
      <v-icon>{{this.paymentIcon}}</v-icon>
    </v-btn>

    <v-btn @click="toBasket()">
      <span class="font-weight-bold mt-1">Basket</span>
      <v-icon>{{this.basketIcon}}</v-icon>
    </v-btn>

  </v-bottom-navigation>

</template>
<script>
import router from '@/router'
  export default {
    data () {
      return {
        homeIcon: '$homeIconActive',
        customersIcon: '$customersIconInactive',
        paymentIcon: '$paymentIconInactive',
        basketIcon: '$basketIconInactive',
        catalogIcon: '$catalogIconInactive',
      }
    },
    methods: {
      toHome() {
        this.homeIcon = '$homeIconActive', this.customersIcon = '$customersIconInactive', this.catalogIcon = '$catalogIconInactive', this.paymentIcon = '$this.$paymentIconInactive', this.basketIcon = '$basketIconInactive'
        router.push('/')
      },
      toCustomers() {
        this.homeIcon = '$homeIconInactive', this.customersIcon = '$customersIconActive', this.catalogIcon = '$catalogIconInactive', this.paymentIcon = '$this.$paymentIconInactive', this.basketIcon = '$basketIconInactive'
        router.push('customers')
      },
      // toCatalog() {
      //   this.homeIcon = '$homeIconInactive', this.customersIcon = '$customersIconInactive', this.catalogIcon = '$catalogIconActive', this.catalogIcon = '$boxInactiveIcon',this.basketIcon = '$basketIconInactive'
      //   router.push('catalog')
      // },
      toPayments() {
        this.homeIcon = '$homeIconInactive', this.customersIcon = '$customersIconInactive', this.catalogIcon = '$catalogIconInactive', this.paymentIcon = '$this.$paymentIconActive', this.basketIcon = '$basketIconInactive'
        router.push('paymentList')
      },
      toBasket() {
        this.homeIcon = '$homeIconInactive', this.customersIcon = '$customersIconInactive', this.catalogIcon = '$catalogIconInactive', this.paymentIcon = '$this.$paymentIconInactive', this.basketIcon = '$basketIconActive'
        router.push('basket')
      },
    }
  }
</script>
<style scoped>
</style>
