import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import kiraakLogo from '../components/icons/kiraakLogo.vue'
import homeIconActive from '../components/icons/homeIconActive.vue'
import homeIconInactive from '../components/icons/homeIconInactive.vue'
import customersIconActive from '../components/icons/customersIconActive.vue'
import customersIconInactive from '../components/icons/customersIconInactive.vue'
import catalogIconActive from '../components/icons/catalogIconActive.vue'
import catalogIconInactive from '../components/icons/catalogIconInactive.vue'
import basketIconActive from '../components/icons/basketIconActive.vue'
import basketIconInactive from '../components/icons/basketIconInactive.vue'
import paymentIconActive from '../components/icons/paymentIconActive.vue'
import paymentIconInactive from '../components/icons/paymentIconInactive.vue'
import houseNoIcon from '../components/icons/houseNoIcon.vue'
import locationIcon from '../components/icons/locationIcon.vue'
import xMarkIcon from '../components/icons/xMarkIcon.vue'
import cartBasketIcon from '../components/icons/cartBasketIcon.vue'
import copy from '../components/icons/copy.vue'

import manIcon from '../components/icons/manIcon.vue'
import womanIcon from '../components/icons/womanIcon.vue'

import rupeeIcon from '../components/icons/rupeeIcon.vue'
import rupeeIcon2 from '../components/icons/rupeeIcon2.vue'
import appleIcon from '../components/icons/appleIcon.vue'
import bananaIcon from '../components/icons/bananaIcon.vue'
import berryIcon from '../components/icons/berryIcon.vue'
import cherriesIcon from '../components/icons/cherriesIcon.vue'
import kiwiIcon from '../components/icons/kiwiIcon.vue'
import orangeIcon from '../components/icons/orangeIcon.vue'
import papayaIcon from '../components/icons/papayaIcon.vue'
import pineappleIcon from '../components/icons/pineappleIcon.vue'
import pineappleChunksIcon from '../components/icons/fruits/pineappleChunksIcon.vue'
import strawberryIcon from '../components/icons/strawberryIcon.vue'
import custardIcon from '../components/icons/custardIcon.vue'
import pomegranateIcon from '../components/icons/fruits/pomegranateIcon.vue'
import pomegranatePeeledIcon from '../components/icons/fruits/pomegranatePeeledIcon.vue'

import notAvailableIcon from '../components/icons/notAvailableIcon.vue'
import boxIcon from '../components/icons/boxIcon.vue'

import boxActiveIcon from '../components/icons/boxActiveIcon.vue'
import boxInactiveIcon from '../components/icons/boxInactiveIcon.vue'

import rupeeNoteIcon from '../components/icons/rupeeNoteIcon.vue'
import cartIcon from '../components/icons/cartIcon.vue'

import rocketIcon from '../components/icons/rocketIcon.vue'

import hiIcon from '../components/icons/hiIcon.vue'

import jackIcon from '../components/icons/fruits/jackIcon.vue'
import mosambiIcon from '../components/icons/fruits/mosambiIcon.vue'
import pearIcon from '../components/icons/fruits/pearIcon.vue'
import pearBeautyIcon from '../components/icons/fruits/pearBeautyIcon.vue'
import starIcon from '../components/icons/fruits/starIcon.vue'
import waterAppleIcon from '../components/icons/fruits/waterAppleIcon.vue'
import pomeloIcon from '../components/icons/fruits/pomeloIcon.vue'
import pomeloPeeledIcon from '../components/icons/fruits/pomeloPeeledIcon.vue'
import papayaChunksIcon from '../components/icons/fruits/papayaChunksIcon.vue'
import plumIcon from '../components/icons/fruits/plumIcon.vue'
import appleBerIcon from '../components/icons/fruits/appleBerIcon.vue'
import guavaIcon from '../components/icons/fruits/guavaIcon.vue'
import avocadoIcon from '../components/icons/fruits/avocadoIcon.vue'
import mangosteenIcon from '../components/icons/fruits/mangosteenIcon.vue'
import rambutanIcon from '../components/icons/fruits/rambutanIcon.vue'
import sapotaIcon from '../components/icons/fruits/sapotaIcon.vue'
import passionIcon from '../components/icons/fruits/passionIcon.vue'
import dragonRedIcon from '../components/icons/fruits/dragonRedIcon.vue'
import tenderCoconutIcon from '../components/icons/fruits/tenderCoconutIcon.vue'
import coconutPeeledIcon from '../components/icons/fruits/coconutPeeledIcon.vue'
import coconutChunksIcon from '../components/icons/fruits/coconutChunksIcon.vue'
import watermelonIcon from '../components/icons/fruits/watermelonIcon.vue'
import longanIcon from '../components/icons/fruits/longanIcon.vue'
import muskmelonIcon from '../components/icons/fruits/muskmelonIcon.vue'
import muskmelonChunksIcon from '../components/icons/fruits/muskmelonChunksIcon.vue'
import apricotIcon from '../components/icons/fruits/apricotIcon.vue'
import pineappleSlicesIcon from '../components/icons/fruits/pineappleSlicesIcon.vue'
import dragonWhiteIcon from '../components/icons/fruits/dragonWhiteIcon.vue'
import peachIcon from '../components/icons/fruits/peachIcon.vue'
import blueGrapeIcon from '../components/icons/fruits/blueGrapeIcon.vue'
import redGrapeIcon from '../components/icons/fruits/redGrapeIcon.vue'
import grapeFruitIcon from '../components/icons/fruits/grapeFruitIcon.vue'
import nagpurOrangeIcon from '../components/icons/fruits/nagpurOrangeIcon.vue'
import datesIcon from '../components/icons/fruits/datesIcon.vue'
import starGooseberryIcon from '../components/icons/fruits/starGooseberryIcon.vue'

import fruitDefaultIcon from '../components/icons/fruits/fruitDefaultIcon.vue'




Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            kiraakLogo: { // name of our custom icon
            component: kiraakLogo, // our custom component
          },
            homeIconActive: {
            component: homeIconActive,
          },
            homeIconInactive: {
            component: homeIconInactive,
          },
          paymentIconInactive: {
            component: paymentIconInactive,
          },
          paymentIconActive: {
            component: paymentIconActive,
          },
            customersIconActive: { 
            component: customersIconActive,
          },
            customersIconInactive: {
            component: customersIconInactive,
          },
            catalogIconActive: { 
            component: catalogIconActive,
          },
            catalogIconInactive: { 
            component: catalogIconInactive,
          },
            basketIconActive: { 
            component: basketIconActive,
          },
            basketIconInactive: {
            component: basketIconInactive,
          },
            rupeeIcon: {
            component: rupeeIcon,
          },
          rupeeIcon2: {
            component: rupeeIcon2,
          },
            appleIcon: {
            component: appleIcon,
          },
          berryIcon: {
            component: berryIcon,
          },
          copy: {
            component: copy,
          },
          bananaIcon: {
            component: bananaIcon,
          },
          cherriesIcon: {
            component: cherriesIcon,
          },
          kiwiIcon: {
            component: kiwiIcon,
          },
          orangeIcon: {
            component: orangeIcon,
          },
          papayaIcon: {
            component: papayaIcon,
          },
          pineappleIcon: {
            component: pineappleIcon,
          },
          pineappleChunksIcon: {
            component: pineappleChunksIcon,
          },
          strawberryIcon: {
            component: strawberryIcon,
          },
          custardIcon: {
            component: custardIcon,
          },
          pomegranateIcon: {
            component: pomegranateIcon,
          },
          pomegranatePeeledIcon: {
            component: pomegranatePeeledIcon,
          },
          manIcon: {
            component: manIcon,
          },
          womanIcon: {
            component: womanIcon,
          },
          houseNoIcon: {
            component: houseNoIcon,
          },
          locationIcon: {
            component: locationIcon,
          },
          xMarkIcon: {
            component: xMarkIcon,
          },
          cartBasketIcon: {
            component: cartBasketIcon,
          },
          notAvailableIcon: {
            component: notAvailableIcon,
          },
          boxIcon: {
            component: boxIcon,
          },
          boxActiveIcon: {
            component: boxActiveIcon,
          },
          boxInactiveIcon: {
            component: boxInactiveIcon,
          },
          rupeeNoteIcon: {
            component: rupeeNoteIcon,
          },
          cartIcon: {
            component: cartIcon
          },
          rocketIcon: {
            component: rocketIcon
          },
          hiIcon: {
            component: hiIcon
          },
          jackIcon: {
            component: jackIcon
          },
          mosambiIcon: {
            component: mosambiIcon
          },
          pearBeautyIcon: {
            component: pearBeautyIcon
          },
          pearIcon: {
            component: pearIcon
          },
          starIcon: {
            component: starIcon
          },
          waterAppleIcon: {
            component: waterAppleIcon
          },
          pomeloIcon: {
            component: pomeloIcon
          },
          pomeloPeeledIcon: {
            component: pomeloPeeledIcon
          },
          papayaChunksIcon: {
            component: papayaChunksIcon
          },
          plumIcon: {
            component: plumIcon
          },
          appleBerIcon: {
            component: appleBerIcon
          },
          guavaIcon: {
            component: guavaIcon
          },
          avocadoIcon: {
            component: avocadoIcon
          },
          mangosteenIcon: {
            component: mangosteenIcon
          },
          rambutanIcon: {
            component: rambutanIcon
          },
          sapotaIcon: {
            component: sapotaIcon
          },
          passionIcon: {
            component: passionIcon
          },
          dragonRedIcon: {
            component: dragonRedIcon
          },
          tenderCoconutIcon: {
            component: tenderCoconutIcon
          },
          coconutPeeledIcon: {
            component: coconutPeeledIcon
          },
          coconutChunksIcon: {
            component: coconutChunksIcon
          },
          watermelonIcon: {
            component: watermelonIcon
          },
          longanIcon: {
            component: longanIcon
          },
          muskmelonIcon: {
            component: muskmelonIcon
          },
          muskmelonChunksIcon: {
            component: muskmelonChunksIcon
          },
          apricotIcon: {
            component: apricotIcon
          },
          pineappleSlicesIcon: {
            component: pineappleSlicesIcon
          },
          dragonWhiteIcon: {
            component: dragonWhiteIcon
          },
          peachIcon: {
            component: peachIcon
          },
          blueGrapeIcon: {
            component: blueGrapeIcon
          },
          redGrapeIcon: {
            component: redGrapeIcon
          },
          grapeFruitIcon: {
            component: grapeFruitIcon
          },
          fruitDefaultIcon: {
            component: fruitDefaultIcon
          },
          nagpurOrangeIcon: {
            component: nagpurOrangeIcon
          },
          datesIcon: {
            component: datesIcon
          },
          starGooseberryIcon: {
            component: starGooseberryIcon
          },
        },
      },
});
