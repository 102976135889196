import axios from 'axios'
import store from '../store' 

export default () => {
  return axios.create({
    baseURL: `https://kiraak.tech`,
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  })
}
// LIVE : baseURL: `https://kiraak.tech`,
// LOCAL : baseURL: `http://localhost:8081`,
// DVELOPMENT : baseURL: `http://localhost:81`,