import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
        path: '/',
        name: 'home',
        component: () => import('./components/homeTab/Home.vue')
    },
    {
      path: '/customers',
      name: 'customers',
      component: () => import('./components/customersTab/CustomersMain.vue')
    },
    {
        path: '/basket',
        name: 'basket',
        component: () => import('./components/basketTab/BasketMain.vue')
    },
    // {
    //   path: '/payment',
    //   name: 'payment',
    //   component: () => import('./components/paymentsTab/PaymentMain.vue')
    // },
    // {
    //     path: '/catalog',
    //     name: 'catalog',
    //     component: () => import('./components/catalogTab/CatalogMain.vue')
    // },
    {
      path: '/paymentList',
      name: 'paymentList',
      component: () => import('./components/paymentTab/PaymentMain.vue')
    },
    {
      path: '/edit-payment/:id',
      name: 'editPayment',
      component: () => import('./components/paymentTab/EditPayment.vue')
    },
    // {
    //     path: '/bucket',
    //     name: 'bucket',
    //     component: () => import('./components/bucketTab/BucketMain.vue')
    // },
    // {
    //     path: '/catalog',
    //     name: 'catalog',
    //     component: () => import('./components/catalogTab/CatalogMain.vue')
    // },
    {
      path: '/viewCustomer',
      name: 'viewCustomer',
      component: () => import('./components/customersTab/viewCustomer/ViewCustomerMain.vue')
    },
    {
      path: '/createCustomer',
      name: 'createCustomer',
      component: () => import('./components/customersTab/createCustomer/CreateCustomerMain.vue')
    },
	{
      path: '/editCustomer/:id',
      name: 'editCustomer',
      component: () => import('./components/customersTab/createCustomer/EditCustomerMain.vue')
    },
    // {
    //   path: '/payments',
    //   name: 'payments',
    //   component: () => import('./components/payments/PaymentsMain.vue')
    // },
    {
      path: '/login',
      name: 'login',
      component: () => import('./components/auth/Login.vue')
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('./components/auth/Profile.vue')
    }
  ]
})
