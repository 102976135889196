import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: true,
    plugins: [
        createPersistedState()
    ],
    state: {
        token: null,
        partnerInfo: '',
        isPartnerLoggedIn: false
    },

    mutations: {
        setToken (state, token) {
            if (token) {
                state.token = token
                state.isPartnerLoggedIn = true
            } else {
                state.isPartnerLoggedIn = false
                state.token = null
            }
        },
        setPartnerInfo (state, partnerInfo) {
            state.partnerInfo = partnerInfo
        }
    },
    actions: {
        setToken ({ commit }, token) {
            commit('setToken', token)
        },
        setPartnerInfo ({ commit }, partnerInfo) {
            commit('setPartnerInfo', partnerInfo)
        }
    }

})