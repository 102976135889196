<template>
        <v-main v-if="!this.$store.state.isPartnerLoggedIn">
         <v-container fluid fill-height>
             
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                    <span class="d-flex justify-center mb-2">
                        <v-icon class="ml-2" size="100"> $kiraakLogo </v-icon>
                    </span>
                    <span class="logo d-flex justify-center">
                        KIRAAK
                    </span>
                    <span class="ml-8 subTitle d-flex justify-center">
                        Partner App
                    </span>
                  
                    <span class="pageTitle d-flex mt-12 ml-5">
                        Login
                    </span>
                    <v-row no-gutters>
                        <v-col>
                            <div class="mt-4 ml-5 mr-5">
                                <v-form ref="form">
                                    <v-text-field :rules="mobile_rules" v-model="credentials.mobile" class="textInput" outlined full-width single-line label="Mobile No." background-color="#ffffff" color="#4B9C6D" autocomplete="off" prepend-inner-icon="mdi-phone"></v-text-field>
                                    <v-text-field :rules="password_rules" v-model="credentials.password" class="textInput" outlined full-width single-line label="Password" background-color="#ffffff" color="#4B9C6D" autocomplete="off" prepend-inner-icon="mdi-lock" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show" :type="show ? 'text' : 'password'"></v-text-field>
                                </v-form>
                                <!-- <v-text-field outlined full-width label="Password"  background-color="#ffffff" color="#4B9C6D" :append-icon="show ? 'visibility' : 'visibility_off'" @click:append="show = !show" :type="show ? 'text' : 'password'" ></v-text-field> -->
                            </div>
                        </v-col> 
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <div class="mr-4 ml-4">
                                <v-btn @click="login()" class="saveCustomer" height="50px" large block text dark elevation="0">
                                    Login
                                </v-btn>
                            </div>
                        </v-col>  
                    </v-row>
               </v-flex>
            </v-layout>
            <v-snackbar :timeout="5000" :value="true" v-if="errorMessage !=''"  absolute bottom color="#E14B4B" text>
                {{errorMessage}}
            </v-snackbar>
         </v-container>
      </v-main>  
      
</template>
<script>
import authServices from '../../services/authServices'
export default {
    data() {
        return {
            errorMessage: '',
            show: false,
            credentials: {
                mobile: '',
                password: ''
            },
            mobile_rules: [
                        v => !!v || 'Mobile is required',
                        v => /^\d+$/.test(v) || 'Only numbers are allowed',
                        v => /^[A-Za-z0-9 ]+$/.test(v) || 'Special characters are not allowed',
                        v => (v && v.length == 10) || 'Mobile should have only 10 numbers',
                    ],
            password_rules: [v => !!v || 'Password is required'],
            errorCode: '',
        }
    },
    methods: {

       async login () {
               try {
                if (this.$refs.form.validate()) {
                    await authServices.login(this.credentials).then(result => {
                    if (result.data.isAuthenticated) {
                        this.$store.dispatch('setToken', result.data.token)
                        this.$store.dispatch('setPartnerInfo', result.data.partnerInfo)
                        this.$router.push({name: "home"})
                    } else {
                        this.errorMessage = result.data.message
                        this.credentials.mobile = ""
                        this.credentials.password = ""
                    }
               })
                } 
           } catch (err) {
                console.log(err)
           }
        }
    },
    async mounted () {
    }
}
</script>
<style>
.textInput input {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 46px;
  color: #757575 !important;
}
.logo {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 46px;
  color: #F2BA55;
}
.pageTitle {
font-family: Lato;
font-style: normal;
font-weight: 600;
font-size: 18px;
color: #FFFFFF;
opacity: 0.6;
}
.subTitle {
font-family: Lato;
font-style: italic;
font-weight: 500;
font-size: 14px;
line-height: 3px;
color: #F2BA55;
}
.saveCustomer {
text-transform: unset !important;
background: #4B9C6D;
font-family: Lato;
font-style: normal;
font-size: 14px;
line-height: 29px;
color: #FFFFFF;
}
</style>